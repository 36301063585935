import React from 'react'
import './SidebarNewsletter.scss'
import NewsletterForm from '../forms/newsletter-form'
function SidebarNewsletter() {
  return (
    <section className='sidebar-form rounded'>
        <h3>Stay in the loop with our newsletter</h3>
        
        <div className='form-body'>
            <NewsletterForm/>
            {/* <div className='form-field-group'>
                <input type={`text`} placeholder="Name"/>
            </div>
            <div className='form-field-group'>
                <input type={`text`} placeholder="Email Address"/>
            </div>        
            <div className='btn btn-white-outline'>
                Subscribe
            </div> */}
        </div>
        {/* <div className='contact-terms'>
            <span>By clicking Subscribe, you agree to our Terms and Privacy Policy.</span>
        </div> */}
    </section>
  )
}

export default SidebarNewsletter